import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


export default class ScrollTopLeft extends React.Component {
    scrollEvent = null
    scrollTimeout = null
    scrollFn = null

    state = {
        showTop: false,
        showLeft: false
    }

    componentDidMount() {
        this.scrollFn = this.onScroll.bind(this);
        window.addEventListener('scroll', this.scrollFn, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollFn, true);
    }

    onScroll(event) {
        let _this = this;
        clearTimeout( this.scrollTimeout );
        this.scrollTimeout = setTimeout(() => {
            _this.setState({
                ..._this.state,
                showTop: window.scrollY > 0,
                showLeft: window.scrollX > 0
            })
        }, 66);
    }

    scrollTo(param) {
        window.scrollTo({
            [param]: 0,
            behavior: 'smooth',
        })
    }

    render() {
        return (
            <>
                <button onClick={() => this.scrollTo('top')} style={{
                    bottom: (this.state.showTop) ? '30px' : '-46px'
                }} className="btn rounded scrollBtn top">
                    <ArrowUpwardIcon />
                </button>

                <button onClick={() => this.scrollTo('left')} style={{
                    left: (this.state.showLeft) ? '30px' : '-46px'
                }} className="btn rounded scrollBtn left">
                    <ArrowBackIcon />
                </button>
            </>
        )
    }
}

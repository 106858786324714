import React, { Component } from "react";
import _ from "lodash";
import axios from "axios";
import { Button, Dialog, DialogActions, DialogContent, TextField, CircularProgress } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import WebinarDateAsset from "../LandingPageWidgets/WebinarDateAsset";
import RegisterWidget from "../LandingPageWidgets/RegisterWidget";
import CodeSnippet from "../LandingPageWidgets/CodeSnippet";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export class AssetsPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      post_back_url: '',
      url_type: '',
      destination_url: '',
      options: '',
      showWebinarFormDesigner: false,
      webinarFormCode: '',
      availableWidgets: [],
      landing_page: {},
      loading: true
    }
  }

  componentDidMount() {
    const { data } = this.props;

    const { post_back_url, url_type, internal_link_id, landing_page_id } = data;

    this.setState({
      post_back_url, url_type,
      loading: true
    }, async () => {
      const resp = await axios.get(process.env.SERVER_ENDPOINT + `/widgets?media_buy=${data.media_buy_id}`);

      this.setState({ availableWidgets: resp.data.data })
      if (internal_link_id) {
        const linkOptions = await this.getLinkDetails(internal_link_id);
        this.setState({ ...linkOptions })
      }

      if(landing_page_id) {
        const lpResp = await axios.get(process.env.SERVER_ENDPOINT + `/landing-pages?id=${landing_page_id}`);
        this.setState({...this.state, landing_page: lpResp.data.data[0]})
      }

      this.setState({
        loading: false
      })
    });
  }

  async getLinkDetails(id) {
    const { data } = await axios.get(process.env.SERVER_ENDPOINT + `/links/${id}`);
    return _.head(_.get(data, 'links', []));
  }

  xgenerateVendorLink(incomingData) {
    const data = {
      ...incomingData,
      id_method: _.has(data, 'id_method') ? `&${data.id_method}` : ''
    };

    let link = process.env.API_LINK;
    const org = process.env.ORGANIZATION;
    const { url_type, client } = data;
    let wlkey = 'mbid';

    // Traders Agency
    if (org === "Traders Agency") {
      wlkey = 'tambid';
      link = "https://api.tradersagency.com/taLeads?email=<email>&fName=<firstName>&lName=<lastName>&tambid=<%=media_buy_id%>&siteId=<siteId>"
    }

    // Sales Agency
    if (org === 'Traders Agency' && (client !== 'Traders Agency' && client !== 'NOFT Traders')) {
      wlkey = 'sambid';
      link = "https://api.salesagency.com/saLeads?email=<email>&fName=<firstName>&lName=<lastName>&sambid=<%=media_buy_id%>&siteId=<siteId>"
    }

    const returnParams = obj => {
      let res = '';

      _.forEach(_.keys(obj), key => {
        if (_.has(data, obj[key]) && obj[key] && obj[key] !== 'false') res += `&${key}=<%=${obj[key]}%>`
      });

      return res;
    };

    const iid = org !== 'LeadMark' ? returnParams({ iid: 'iterable_project' }) : '';
    // API
    if (url_type === 'api') {
      link = `${link}${org === 'LeadMark' ? '' : returnParams({ iid: 'iterable_project' })}`;
    }

    // CTO
    if (url_type === 'cto') {
      link = `https://api.leadmark.com/redirect?ilid=${data.internal_link_id}&email=<email_address>&fName=<firstName>&lName=<lastName>&siteId=<siteId>${iid}`
    }

    // Webinar
    if (url_type === 'webinar') {
      link = `https://api.webinarlogins.com/gtwWebinarRedirect?${wlkey}=<%=media_buy_id%><%=id_method%>${returnParams({
        client_id: 'gtw_acct_id',
        kywd: 'kywd',
        redirect: 'destination_url'
      })}${iid}`
    } // tambid/sambid/mbid, client_id={gtw_account_id}, kywd, redirect, id_method, iid, ccid

    // Landing Page
    if (url_type === 'landing_page') {
      link = `${this.state.landing_page.live_page_url}?${wlkey}=<%=media_buy_id%>&siteId=<siteId>${org === 'LeadMark' ? `&ccid=<%=client_campaign_id%>` : ''}${iid}`
    } // tambid/sambid/mbid, ccid, siteid, redirect, iid
    try {
      link = _.template(link)(data);
    } catch (e) {
      // console.error(e);
    }
    return link;
  }

  generateVendorLink(data) {
    const { url_type, client } = data;
    const { destination_url } = this.state;

    const doesExist = itm => {
      if (!_.has(data, itm)) {
        return false;
      }
      const i = _.get(data, itm);
      return i && i !== '' && i !== 'false'
    };

    const returnParams = obj => {
      let res = '';
      _.forEach(_.keys(obj), key => {
        if (doesExist(obj[key])) res += `&${key}=<%=${obj[key]}%>`
      });
      return res;
    };

    // determine organization
    let org = '';
    if (process.env.ORGANIZATION === 'Traders Agency') {
      org = 'TA';
    }
    if (process.env.ORGANIZATION === 'Traders Agency' && (client !== 'Traders Agency' && client !== 'NOFT Traders')) {
      org = 'SA';
    }
    if (process.env.ORGANIZATION === 'LeadMark') {
      org = 'LM';
    }

    // determine base url
    let base_url = '';
    switch (url_type) {
      case 'api':
        if (org === 'TA') base_url = 'https://api.tradersagency.com/taLeads?';
        if (org === 'SA') base_url = 'https://api.salesagency.com/saLeads?';
        if (org === 'LM') base_url = 'https://api.leadmark.com/leads?';
        break;
      case 'cto':
        if (org === 'LM') base_url = 'https://api.leadmark.com/redirect?';
        break;
      case 'webinar':
        base_url = 'https://api.webinarlogins.com/gtwWebinarRedirect?';
        break;
      case 'odw_webinar':
        base_url = 'https://api.webinarlogins.com/odwWebinarRedirect?';
        break;
      case 'landing_page':
        base_url = `${this.state.landing_page.live_page_url}?`;
        break;
    }

    // determine parameters
    let parameters = '';
    const iid = org !== 'LeadMark' ? returnParams({ iid: 'iterable_project' }) : '';
    switch (url_type) {
      case 'api':
        if (org === 'LM') {
          parameters += `mbid=<%=media_buy_id%>`;
        }
        if (org === 'TA') {
          parameters += `tambid=<%=media_buy_id%>`;
        }
        if (org === 'SA') {
          parameters += `sambid=<%=media_buy_id%>`;
        }
        if (org === 'LM') {
          parameters += `&ccid=<%=client_campaign_id%>`;
        }
        parameters += '&email=<email_address>&fName=<firstName>&lName=<lastName>&siteId=<siteId>';
        if (org === 'SA') {
          parameters += iid;
        }
        break;
      case 'cto':
        parameters += `ilid=${data.internal_link_id}`;
        parameters += '&email=<email_address>&fName=<firstName>&lName=<lastName>&siteId=<siteId>';
        break;
      case 'webinar':
        if (org === 'LM') {
          parameters += `mbid=<%=media_buy_id%>`;
        }
        if (org === 'TA') {
          parameters += `tambid=<%=media_buy_id%>`;
        }
        if (org === 'SA') {
          parameters += `sambid=<%=media_buy_id%>`;
        }
        if (org === 'SA') {
          parameters += iid;
        }
        if (org === 'LM') {
          parameters += `&ccid=<%=client_campaign_id%>`;
        }

        parameters += `${doesExist('id_method') ? `&<%=id_method%>` : ''}&client_id=<%=client_id%>${returnParams({ kywd: 'kywd', redirect: 'destination_url' })}`;
        break;
      case 'odw_webinar':
        if (org === 'LM') {
          parameters += `mbid=<%=media_buy_id%>`;
        }
        if (org === 'TA') {
          parameters += `tambid=<%=media_buy_id%>`;
        }
        if (org === 'SA') {
          parameters += `sambid=<%=media_buy_id%>`;
        }
        if (org === 'SA') {
          parameters += iid;
        }
        if (org === 'LM') {
          parameters += `&ccid=<%=client_campaign_id%>`;
        }
        
        // parameters += `&webinar_id=<Webinar id goes here>`;
        parameters += `&webinar_id=${data.webinar_id}`;
        parameters += `${doesExist('id_method') ? `&<%=id_method%>` : ''}${returnParams({ redirect: 'destination_url' })}`;
        break;

      case 'landing_page':
        if (org === 'LM') {
          parameters += `mbid=<%=media_buy_id%>`;
        }
        if (org === 'TA') {
          parameters += `tambid=<%=media_buy_id%>`;
        }
        if (org === 'SA') {
          parameters += `sambid=<%=media_buy_id%>`;
        }
        if (org === 'SA') {
          parameters += iid;
        }
        parameters += `&siteId=<siteId>`;
        if (org === 'LM') {
          parameters += `&ccid=<%=client_campaign_id%>`;
        }
        // console.log({ exists: doesExist('iterable_project') });
        break;
    }

    let link;
    try {
      link = `${base_url}${_.template(parameters)(data)}`
    } catch (e) {
      link = `${base_url}${parameters}`
    }

    return link;
  }

  render() {
    const { data, closePopup } = this.props;
    const { post_back_url, url_type, destination_url, options, expired_destination_url } = this.state;

    const optionsObj = options ? JSON.parse(options) : {};

    const linkData = {
      ...data,
      ...this.state,
      ...optionsObj
    };
    const link = this.generateVendorLink(linkData);
    const org = process.env.ORGANIZATION;
    
    return (
      <Dialog maxWidth={'lg'} fullWidth open aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">Assets - {data.media_buy_name}</DialogTitle>
        <DialogContent>
          {this.state.loading ?
            <CircularProgress /> :
          <>
          {url_type === "landing_page" && (
            <div style={{ marginBottom: 50 }}>
              <h3>LANDING PAGE NAME</h3>
              <p>{this.state.landing_page.name_internal}</p>
            </div>
          )}

          <div style={{ marginBottom: 50 }}>
            {url_type === "landing_page" ? (
              <h3>LANDING PAGE URL</h3>
            ) : (
                <h3>VENDOR LINK</h3>
              )}
            <TextField
              variant="outlined"
              multiline
              style={{ width: "100%" }}
              rowsMax="4"
              value={link}
            />
          </div>

          {(url_type === "webinar" || url_type === "cto") && (
            <div style={{ marginBottom: 50 }}>
              <h3>DESTINATION URL</h3>
              <TextField
                variant="outlined"
                multiline
                style={{ width: "100%" }}
                rowsMax="4"
                value={destination_url}
              />
            </div>
          )}

          {(expired_destination_url && expired_destination_url.length && expired_destination_url.length > 0) && (
            <div style={{ marginBottom: 50 }}>
              <h3>OFFER EXPIRED URL</h3>
              <TextField
                variant="outlined"
                multiline
                style={{ width: "100%" }}
                rowsMax="4"
                value={expired_destination_url}
              />
            </div>
          )}

          {org !== "Traders Agency" &&
            <div style={{ marginBottom: 50 }}>
              <h3>CLIENT LINK</h3>
              <TextField
                variant="outlined"
                multiline
                style={{ width: "100%" }}
                rowsMax="4"
                value={post_back_url}
              />
            </div>
          }

          </>
          }
        </DialogContent>

        <DialogActions>
          <a className="btn createNew__btn" href="/media-planner/request">
            Create Another Media Buy
          </a>
          <Button style={{textTransform:'none'}} color="primary" onClick={closePopup}>
            I'm Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}
